@import "~bootstrap/scss/bootstrap"
@import "./basedata/variables"

*
  border-color: $pm-primary !important
  &::placeholder
    color: tint-color($pm-primary, 40%) !important

  input
    color: tint-color($pm-primary, 20%) !important

.btn-primary
  background-color: $pm-dark !important
  color: $white !important

label, h1, h2, h3, h4, h5, h6
  color: $pm-primary !important
  .text-muted
    color: tint-color($pm-primary, 20%) !important

.card .card-header h5:after
  background-color: tint-color($pm-primary, 70%) !important

.pcoded-navbar
  background-color: $pm-dark !important
  color: $white !important

  label
    color: $white !important

.pcoded-navbar .header-logo
  background: none !important

.password-rules
  padding: 8px
  list-style: none
  color: $pm-primary
  +fontDefault
  font-size: 13px
  margin-top: 8px

.label-error
  color: $red-300
  padding-top: 16px

.form-floating label
  border: 0 !important

.local-loader
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  z-index: 2
  background: rgba(255, 255, 255, 0.7)
  text-align: center

  .fa
    font-size: 50px
    margin: 45% auto

.form-control.check-box-interno
  width: 20px !important
  margin-top: 6px

.word-breaker
  white-space: initial
  font-size: 12px
  cursor: pointer

.user-data-game
  +fontDefault
  color: $pm-primary
  span
    font-weight: bold

.text-right
  text-align: right

.report-paginate
  justify-content: center

  .page-item

    .page-link
      color: $pm-primary
    &.active
      .page-link
        background-color: $pm-primary !important
        color: $white !important

.login-logo
    max-width: 140px

a.btn.form-control.btn-default
  color: $pm-primary

.link-coordinator
    text-decoration: none
    text-transform: uppercase
    font-size: 10px
    float: right
    color: $pm-dark !important
    font-weight: bold

    &:hover
      text-decoration: underline

.copyright-botton
  background-color: $pm-primary
  padding: 6px 0
  text-align: center
  font-size: 12px
  color: $white
  margin-top: -30px
  a
    color: $white
    text-decoration: none

.multiclubes-tv
  background-color: $pm-primary
  padding-right: 0 !important

  .multiclubes-image
    width: 80%
    aspect-ratio: 1/1
    border-radius: 100%
    border: 20px solid $white

.multiclube-tv-data
  h1
    font-size: 7em
    line-height: 1em
    color: $white !important
    margin-bottom: 1em
    margin-top: 1em

  p
    display: block
    background-color: $white
    font-size: 2.2em
    color: $pm-primary  !important
    padding: 16px
    margin-bottom: 0
    text-transform: uppercase

  span
    display: block
    font-size: 1.5em
    text-transform: none

.terminal-list
  tr:hover
    opacity: 0.6
    cursor: pointer






/* Container for the entire timeline */
.timeline-container
  display: flex
  justify-content: center
  padding: 40px 0
  background: #f0f4f8 /* Light background */
  border-radius: 15px
  margin: 20px


/* The horizontal timeline itself */
.timeline
  display: flex
  align-items: center
  justify-content: space-around
  position: relative
  width: 90%


/* Individual timeline item */
.timeline-item
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  width: 180px


/* Connecting line between items */
.timeline::before
  content: ''
  position: absolute
  top: 20%
  left: 0
  right: 0
  height: 4px
  background: linear-gradient(145deg, #9fb8ad, #1fc8db, #2cb5e8) /* Line gradient */
  z-index: 1


/* Circular badge for each item */
.timeline-badge
  width: 50px
  height: 50px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-size: 1.2rem
  z-index: 2
  position: relative
  margin-bottom: 15px

  &:active
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) /* Shadow for depth */


/* Positioning and styling for the timestamp */
.timeline-timestamp
  position: absolute
  bottom: -25px
  font-size: 0.8rem
  color: #444
  background: #fff
  padding: 2px 5px
  border-radius: 5px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)


/* Content box for each item */
.timeline-content
  // background: #fff
  padding: 15px
  border-radius: 10px
  text-align: center
  width: 150px
  height: 70px
  // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) /* Shadow for depth */
  position: relative
  z-index: 2


/* Content title styling */
.timeline-content h5
  margin: 0
  font-size: 0.8rem
  color: #333
  font-weight: bold

.sync-footer
  h6
    font-size: 14px
    color: $gray-400
    font-weight: normal
    
    span
      font-size: 12px
      color: $gray-600

.sync-devices
  display: flex
  list-style: none
  flex-wrap: wrap
  margin: 0
  padding: 0

  li
    padding: 4px
    border-radius: 6px
    font-size: 12px
    background-color: $red-500
    margin: 4px
    &.completed,
    &.failed
      background-color: $blue-500  

.passport-title
  font-size: 18px
  margin-top: 38px

  span
    color: $red-600 !important

.meupiru
  height: 200px


// custom club colors
.swal2-success
  .swal2-success-line-tip,
  .swal2-success-line-long
    background-color: $blue-300 !important