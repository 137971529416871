@import "./../../basedata/variables"

.pagination
  display: flex
  justify-content: center
  align-items: center

  button
    margin: 0 5px
    padding: 5px 10px
    border: 1px solid $white
    border-radius: 6px
    background-color: $white
    cursor: pointer

    &:disabled
      opacity: 0.5
      cursor: default

    &.active
      background-color: $blue
      color: $white
      border-color: $blue
